import React from 'react';

export { default } from './components/MainContainer';

export const defaultSettings = {
  settingsKey: 'tsPerspectiveList',
  orderBy: true,
  sortBy: 'byName',
  layoutType: 'row', // row grid
  singleClickAction: 'openInternal', // openInternal openExternal
  entrySize: 'small', // small, normal, big
  thumbnailMode: 'contain', // cover contain
  showDirectories: true,
  showTags: true
};
